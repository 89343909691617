<template>
  <el-dialog
    class="notification_dialog"
    :visible="show"
    width="75%"
    @close="handleClose"
    center
  >
    <div class="notification_dialog_content" ref="notificationDialog">
      <h2>告知书</h2>
      <p>
        餐饮企业名称：
        <span>{{ showData.Name }}</span>
      </p>
      <p>
        餐饮企业地址：
        <span>{{ showData.Address }}</span>
      </p>
      <p>
        营业执照：
        <span>{{ showData.BlNo }}</span>
      </p>
      <p>
        联系人：
        <span>{{ showData.Contact }}</span
        >&nbsp;&nbsp;&nbsp;&nbsp;联系方式：
        <span>{{ showData.Mobile }}</span>
      </p>
      <h4>一、餐饮企业在线监测情况汇总：</h4>
      <p>本餐饮场所基本信息及超标报警情况：</p>
      <ol>
        <li>
          <h5>{{ showData.beginDate }}至{{ showData.endDate }}</h5>
          <template>
            <!-- <ve-line :data="dataOption" :settings="chartSettings" :grid="grid" :data-empty="dataOption.rows.length===0" /> -->
            <ve-histogram
              :data="showData.dataOption"
              :mark-line="showData.markLine"
              :extend="extend"
              :colors="['#00A1FF', '#F5B049', '#009999']"
              :settings="chartSettings"
              :grid="grid"
              :data-empty="showData.dataOption.rows.length === 0"
            />
          </template>
          <el-table
            :data="showData.tableData"
            border
            size="mini"
            style="width: 100%"
          >
            <el-table-column prop="localeName" label="监测点"></el-table-column>
            <el-table-column prop="mn" label="设备编号"></el-table-column>
            <el-table-column
              prop="complianceDays"
              label="达标天数"
            ></el-table-column>
            <el-table-column
              prop="excessDays"
              label="超标天数"
            ></el-table-column>
            <el-table-column
              prop="filterAbnormallyUsedDays"
              label="净化器不正常天数"
            ></el-table-column>
            <el-table-column
              prop="abnormalOfflineDays"
              label="异常离线天数"
            ></el-table-column>
            <el-table-column
              prop="healthCodeColor"
              label="油烟健康码"
            >
              <template slot-scope="scope">
                <span class="iconfont" :class="scope.row.healthCodeColor">&#xe704;</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ol>
      <h4>二、餐饮企业油烟监管状况分析</h4>
      <ol>
        <li>
          <h5>改善情况</h5>
          <el-input
            type="textarea"
            v-model="textarea01"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
          ></el-input>
        </li>
        <li>
          <h5>目前存在的问题：</h5>
          <el-checkbox-group class="checkbox_group_box" v-model="checkList">
            <el-checkbox label="净化器失效"></el-checkbox>
            <el-checkbox label="净化器与风机未联动"></el-checkbox>
            <el-checkbox
              label="一条烟道上存在两台以上风机，且有风机与净化器不联动"
            ></el-checkbox>
            <el-checkbox
              label="排烟系统存在未安装油烟净化设施的出口或分支"
            ></el-checkbox>
          </el-checkbox-group>
        </li>
        <li>
          <h5>可能存在的风险</h5>
          <el-checkbox-group class="checkbox_group_box" v-model="checkList">
            <el-checkbox
              label="全部或部分烟道未安装油烟净化器或净化器失效；餐厨油烟无组织排放；"
            ></el-checkbox>
            <div>
              《中华人民共和国大气污染防治法 第一百一十八条
              违反本法规定，排放油烟的餐饮服务业经营者未安装油烟净化设施、不正常使用油烟净化设施或者未采取其他油烟净化措施，超过排放标准排放油烟的，由县级以上地方人民政府确定的监督管理部门责令改正，处五千元以上五万元以下的罚款；拒不改正的，责令停业整治。
              <br />违反本法规定，在居民住宅楼、未配套设立专用烟道的商住综合楼、商住综合楼内与居住层相邻的商业楼层内新建、改建、扩建产生油烟、异味、废气的餐饮服务项目的，由县级以上地方人民政府确定的监督管理部门责令改正；拒不改正的，予以关闭，并处一万元以上十万元以下的罚款。
              <el-input
                type="textarea"
                v-model="textarea03"
                :autosize="{ minRows: 6, maxRows: 12 }"
                placeholder="请输入内容"
              ></el-input>
              <!-- <br />《杭州市服务行业环境保护管理办法》第十一条
              产生油烟、恶臭的服务项目,其经营者必须配套设置油烟、恶臭的污染防治设施,油烟、恶臭经处理后应当符合国家和地方规定的排放标准。
              <br />《杭州市服务行业环境保护管理办法》第十三条
              服务项目经营者不得采用下列方式排放油烟:
              <br />&nbsp;&nbsp;(一)不经过油烟排气筒或专用烟道无组织排放;
              <br />&nbsp;&nbsp;(二)经城市公共雨水或者污水管道排放 -->
            </div>
            <br />
            <el-checkbox label="油烟净化器与风机是否联动："></el-checkbox>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;根据GB18483—2001第5.1条，排放油烟餐饮单位必须安装油烟净化设备，并保证操作期间按要求运行。参考国内部分地区已颁布的地方管理办法（北京地标：DB11/1488_2018），餐饮单位安装油烟净化系统时，建议要求商家净化设备与排风机联动，排除偷排漏排的可能，便于日常管理。
            </div>
          </el-checkbox-group>
        </li>
        <li>
          <h5>改善建议：</h5>
          <el-input
            type="textarea"
            v-model="textarea02"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
          ></el-input>
        </li>
      </ol>
      <div class="date_box">日期：{{ today }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="downloadPdf" :loading="downloading"
        >生成并下载pdf</el-button
      >
      <!-- <el-button type="primary" @click="saveCont"
        >保存内容</el-button
      > -->
    </span>
  </el-dialog>
</template>
<script>
import "v-charts/lib/style.css";
import VeHistogram from "v-charts/lib/histogram.common";
export default {
  props: ["show", "showData"],
  components: { VeHistogram },
  data() {
    return {
      downloading: false, // 生成并下载pdf按钮 加载状态
      checkList: [],
      tableData: [],
      obj: {},
      textarea01: "",
      textarea02: "",
      textarea03: `《杭州市服务行业环境保护管理办法》第十一条
              产生油烟、恶臭的服务项目,其经营者必须配套设置油烟、恶臭的污染防治设施,油烟、恶臭经处理后应当符合国家和地方规定的排放标准。
              《杭州市服务行业环境保护管理办法》第十三条
              服务项目经营者不得采用下列方式排放油烟:
                (一)不经过油烟排气筒或专用烟道无组织排放;
                (二)经城市公共雨水或者污水管道排放.`,
      today: "",
      startDate: "",
      endDate: "",
      dataOption: {
        columns: ["采集时间", "油烟折算浓度", "颗粒物折算浓度", "非甲烷总烃折算浓度"],
        rows: [],
      },
      markLine: null,
      extend: {
        // series: {
        //   barWidth: 20
        // },
        "xAxis.0.axisLabel.rotate": 45,
      },
      chartSettings: {
        metrics: ["油烟折算浓度", "颗粒物折算浓度", "非甲烷总烃折算浓度"],
        dimension: ["采集时间"],
        // xAxisType: 'time',
        yAxisName: ["单位:mg/m³"],
      },
      grid: {
        show: true,
        top: 50,
        left: 10,
        // backgroundColor: '#404a59'
      },
    };
  },
  watch: {
    showData: (v) => {
      // console.log(v);
    },
  },
  created() {
    this.getxDates(31);
  },
  mounted() {
    // this.getData()
  },
  methods: {
    /**
     * @description 生成并下载pdf
     */
    downloadPdf() {
      this.downloading = true;
      this.ExportSavePdf(this.$refs.notificationDialog, "一点一档").then(
        (res) => {
          // console.log(res); //返回的是base64
          const par = {
            LocaleId: this.showData.LocaleId,
            BeginDate: this.showData.beginDate,
            EndDate: this.showData.endDate,
            Archive: res,
          };
          this.$post("admin/createLocaleArchive", par).then(() => {
            setTimeout(() => {
              this.downloading = false;
              this.handleClose();
            }, 500);
          });
        }
      );
    },

    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit("on-close", false);
    },
    // getData(){
    //   console.log(this.showData,111111)
    //   const par =  { LocaleId: this.showData.LocaleId }
    //   this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par).then((res) => {
    //     this.tableData = res.content
    //   }).catch(() => {})
    // },
    getxDates(n) {
      let day = new Date();
      const yy = day.getFullYear();
      const mm = day.getMonth() + 1;
      const dd = day.getDate();
      this.today = `${yy}年${mm}月${dd}日`;
    },
    saveCont() {},
  },
};
</script>
<style lang="scss" scoped>
.checkbox_group_box {
  font-size: inherit;
  .el-checkbox {
    display: block;
  }
}

.notification_dialog {
  .notification_dialog_content {
    font-size: 13px;
    h2 {
      text-align: center;
    }
    h4 {
      font-size: 14px;
      margin: 10px 0 5px;
    }

    h5 {
      font-size: 13px;
      margin: 5px 0;
    }

    p {
      margin: 5px 0;
    }

    .date_box {
      text-align: right;
      padding: 10px 0;
      margin-top: 20px;
      font-weight: bold;
      font-weight: 15px;
    }
  }
}
</style>
