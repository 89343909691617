<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visible"
    @close="handleEditDialogClose"
    style="overflow: hidden"
    top="20px"
  >
    <div style="overflow-y: auto; height: 70vh">
      <el-form
        :model="formData"
        label-width="124px"
        :rules="formRule"
        ref="form"
        size="mini"
        class="locale-form"
      >
        <el-row class="form-part">
          <el-col :span="2" class="form-part-title">
            <div class="wordWrapClass">基本信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="name" label="监测点名称">
              <el-input
                placeholder="请输入监测点名称"
                @change="hasChange"
                v-model.trim="formData.name"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="addr" label="详细地址">
              <el-input
                @change="hasChange"
                placeholder="请输入详细地址"
                v-model.trim="formData.addr"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="area_id" label="所属地区">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.area_id"
                @change="hasChange"
                :options="areaTree"
                :props="areaProps"
                filterable
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="lng" label="经度">
              <el-input
                @change="hasChange"
                placeholder="可直接粘贴到此处"
                v-model.trim="formData.lng"
                @keyup.ctrl.86.native="handlePaste"
                :disabled="isView"
              />
            </el-form-item>
            <a
              style="color: #409eff; text-decoration: none; margin-left: 120px"
              target="_blank"
              v-if="!isView"
              href="https://api.map.baidu.com/lbsapi/getpoint/index.html">
              百度坐标拾取系统

            </a>
            <el-form-item prop="stove_length" label="集气罩长">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩长(m)"
                v-model="formData.stove_length"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="stove_area" label="集气灶面积">
              <el-input
                @change="hasChange"
                placeholder="请输入集气灶面积(m²)"
                v-model="formData.stove_area"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="cuisine" label="选择菜系">
              <el-cascader
                :disabled="isView"
                v-model="formData.cuisine"
                :options="cuisine"
                @change="hasChange"></el-cascader>
            </el-form-item>
            <el-form-item prop="scale" label="企业类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择企业类型"
                v-model.trim="formData.scale"
                :disabled="isView"
              >
                <el-option value="大" label="大"/>
                <el-option value="中" label="中"/>
                <el-option value="小" label="小"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="maintainer_id" label="维保人员">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择维保人员"
                v-model="formData.maintainer_id"
                :disabled="isView">
                <el-option v-for="item in maintenanceList"
                           :key="item.Id"
                           :value="item.Id"
                           :label="item.Name"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="contact" label="联系人">
              <el-input
                @change="hasChange"
                v-model.trim="formData.contact"
                placeholder="请输入联系人"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="mobile" label="联系电话">
              <el-input
                @change="hasChange"
                v-model.trim="formData.mobile"
                placeholder="请输入联系电话"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="owner" label="所属单位">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.owner"
                @change="handleOwnerChange"
                :options="customerTree"
                :props="props"
                filterable
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="lat" label="纬度">
              <el-input
                @change="hasChange"
                v-model.trim="formData.lat"
                :disabled="isView"/>
            </el-form-item>
            <a
              style="visibility:hidden; color: #409eff; text-decoration: none; margin-left: 120px"
              target="_blank"
              v-if="!isView"
              href="https://api.map.baidu.com/lbsapi/getpoint/index.html">
              百度坐标拾取系统
            </a>
            <el-form-item prop="stove_width" label="集气罩宽">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩宽"
                v-model="formData.stove_width"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="exhaust_time" label="日均排烟时间">
              <el-input
                @change="hasChange"
                placeholder="请输入日均排烟时间(h)"
                v-model.trim="formData.exhaust_time"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="stove_num" label="基准灶头数">
              <el-input
                @change="hasChange"
                placeholder="请输入基准灶头数"
                v-model="formData.stove_num"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="management_types" label="经营类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择经营类型"
                :disabled="isView"
                v-model.trim="formData.management_types">
                <el-option value="经营型" label="经营型"/>
                <el-option value="非经营型" label="非经营型"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="bl_no" label="营业执照号">
              <el-input
                @change="hasChange"
                placeholder="请输入营业执照号"
                :disabled="isView"
                v-model.trim="formData.bl_no"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="2" class="form-part-title">
            <div class="wordWrapClass">排放系统信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="fan_factory_name" label="风机品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_factory_name"
                :disabled="isView"
                placeholder="请输入风机品牌"/>
            </el-form-item>
            <el-form-item prop="fan_power" label="风机功率">
              <el-input
                @change="hasChange"
                v-model="formData.fan_power"
                :disabled="isView"
                placeholder="请输入风机功率(kW)"/>
            </el-form-item>
            <el-form-item prop="fan_speed" label="烟气流速">
              <el-input
                @change="hasChange"
                v-model="formData.fan_speed"
                :disabled="isView"
                placeholder="请输入风机功率(m/s)"/>
            </el-form-item>
            <el-form-item prop="filter_factory_name" label="净化器品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_factory_name"
                :disabled="isView"
                placeholder="请输入净化器品牌"/>
            </el-form-item>
            <el-form-item prop="purifying_rate" label="净化器净化率">
              <el-input
                @change="hasChange"
                v-model="formData.purifying_rate"
                :disabled="isView"
                placeholder="请输入净化器净化率"/>
            </el-form-item>
            <el-form-item prop="filter_power" label="净化器功率">
              <el-input
                @change="hasChange"
                v-model="formData.filter_power"
                :disabled="isView"
                placeholder="请输入净化器功率(kW)"/>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item prop="fan_factory_model_id" label="风机型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_factory_model_id"
                :disabled="isView"
                placeholder="请输入风机型号"/>
            </el-form-item>
            <el-form-item prop="fan_standard_current" label="风机标准电流">
              <el-input
                @change="hasChange"
                v-model="formData.fan_standard_current"
                :disabled="isView"
                placeholder="请输入风机标准电流(A)"/>
            </el-form-item>
            <el-form-item prop="pipe_area" label="管道截面积">
              <el-input
                @change="hasChange"
                v-model="formData.pipe_area"
                :disabled="isView"
                placeholder="请输入管道截面积(m²)"/>
            </el-form-item>
            <el-form-item prop="filter_factory_model_id" label="净化器型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_factory_model_id"
                :disabled="isView"
                placeholder="请输入净化器型号"/>
            </el-form-item>
            <el-form-item prop="fan_quantity" label="净化器风量">
              <el-input
                @change="hasChange"
                v-model="formData.fan_quantity"
                :disabled="isView"
                placeholder="请输入净化器风量(m³/h)"/>
            </el-form-item>
            <el-form-item prop="filter_standard_current" label="净化器标准电流">
              <el-input
                @change="hasChange"
                v-model="formData.filter_standard_current"
                :disabled="isView"
                placeholder="请输入净化器标准电流(A)"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-part">
          <el-col :span="2" class="form-part-title">
            <div class="wordWrapClass">监测仪信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="voc_factory_name" label="监测仪品牌">
              <el-select
                style="width: 100%"
                @change="handleFactorySelect"
                v-model.trim="formData.voc_factory_name"
                :disabled="isView"
                placeholder="请选择监测仪品牌">
                <el-option v-for="factory in factoryList"
                           :key="factory.factory_name"
                           :label="factory.factory_name"
                           :value="factory.factory_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="principle" label="检测原理">
              <el-select
                @change="hasChange"
                v-model.trim="formData.principle"
                :disabled="isView"
                placeholder="请输入检测原理"
                style="width: 100%">
                <el-option value="光散射" label="光散射"/>
                <el-option value="电化学" label="电化学"/>
                <el-option value="其他" label="其他"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="power_supply_mode" label="接电方式">
              <el-select
                @change="hasChange"
                v-model.trim="formData.power_supply_mode"
                :disabled="isView"
                placeholder="请输入接电方式"
                style="width: 100%">
                <el-option value="风机取电" label="风机取电"/>
                <el-option value="净化器取电" label="净化器取电"/>
                <el-option value="市电" label="市电"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="fan_mn" label="风机监控编码"
                          v-if="this.formData.send_mode===2 ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='净化器取电')">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_mn"
                :disabled="isView"
                placeholder="请输入风机监控编码"/>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="voc_factory_model_id" label="监测仪型号">
              <el-select
                style="width: 100%"
                @change="hasChange"
                v-model.trim="formData.voc_factory_model_id"
                :disabled="isView"
                placeholder="请选择监测仪型号">
                <el-option v-for="item in factoryModelList"
                           :key="item.id"
                           :value="item.id"
                           :label="item.model_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="send_mode" label="传输方式">
              <el-select
                @change="hasChange"
                v-model="formData.send_mode"
                :disabled="isView"
                placeholder="请输入传输方式"
                style="width: 100%">
                <el-option :value="1" label="合并传输"/>
                <el-option :value="2" label="独立传输"/>
                <el-option :value="3" label="两路传输"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="voc_mn" label="监测仪编码">
              <el-input
                @change="hasChange"
                v-model.trim="formData.voc_mn"
                :disabled="isView"
                placeholder="请输入监测仪型号"/>
            </el-form-item>
            <el-form-item prop="filter_mn" label="净化器监控编码"
                          v-if="this.formData.send_mode===2 ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='风机取电') ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='市电')">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_mn"
                :disabled="isView"
                placeholder="请输入净化器监控编码"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="2" class="form-part-title">
            <div class="wordWrapClass">上传照片</div>
          </el-col>
          <el-col :span="20">
            <el-form-item>
              <el-upload
                ref="upload"
                accept=".jpg,.jpeg,.png"
                :show-file-list="false"
                :action="upload()"
                :on-success="handleAvatarSuccess"
                :before-upload="hasChange"
                v-if="!isView"
              >
                <i class="el-icon-plus avatar-uploader-icon"/>
                <div slot="tip">（支持jpg、jpeg、png）</div>
              </el-upload>
              <div class="img-list" v-if="fileList">
                <div class="single-img" v-for="(item, idx) in fileList" :key="idx"
                     style="position: relative; display: inline-block; margin-right: 10px;">
                  <el-image
                    style="width: 100px;height: 100px"
                    :src="item"
                    fit="fit"
                    :preview-src-list="fileList"/>
                  <div v-if="!isView" class="remove-img el-icon-close" @click="removeImg(idx)"></div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part" v-if="isView && title ==='审核'">
          <el-col :span="8">
            <el-form-item label="审核结果">
              <el-select
                @change="hasChange"
                v-model="status">
                <el-option :value="1" label="通过"/>
                <el-option :value="2" label="不通过"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item
              label="原因">
              <el-input
                @input="hasChange"
                v-model="formData.remark"
                placeholder="请输入原因"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer" v-if="title!=='查看'">
      <el-button size="mini" @click.native="handleClose"
      >取消
      </el-button
      >
      <el-button type="primary" size="mini" @click.native="handleSubmit"
      >提交
      </el-button
      >
    </div>
  </el-dialog>
</template>
<script>

import conf from "@/config";
import {mapState} from "vuex";
import {getSPname, getUserInfo, join, loadData, ownerJoin} from "@/util";

export default {
  name: "LocaleVerifyEditDialog",
  props: ['title', 'visible', 'state', 'formData', 'isView', 'cuisine'],
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree','customer']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  data() {
    return {
      userInfo: '',
      changeState: false,
      fileList: [],
      formRule: {
        name: [{required: true, message: '名称不可为空', trigger: 'blur'}],
        contact: [{required: true, message: '联系人不可为空', trigger: 'blur'}],
        addr: [{required: true, message: '详细地址不可为空', trigger: 'blur'}],
        mobile: [{required: true, message: '联系电话不可为空', trigger: 'blur'},{ pattern: /^([1-9][0-9]*)$/, message: '请输入正确额格式' }],
        lng: [{required: true, message: '经度不可为空', trigger: 'blur'}],
        lat: [{required: true, message: '纬度不可为空', trigger: 'blur'}],
        area_id: [{required: true, message: '所属地区不可为空', trigger: 'blur'}],
        owner: [{required: true, message: '所属单位不可为空', trigger: 'blur'}],
        stove_area: [{required: true, message: '集气罩面积不可为空', trigger: 'blur'},{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        exhaust_time: [{required: true, message: '日均排烟时间不可为空', trigger: 'blur'},{ pattern: /^([1-9][0-9]*)$/, message: '请输入正确额格式' }],
        cuisine: [{required: true, message: '菜系不可为空', trigger: 'blur'}],
        stove_num: [{required: true, message: '基准灶头数不可为空', trigger: 'blur'},{ pattern: /^([1-9][0-9]*)$/, message: '请输入正确额格式' }],
        scale: [{required: true, message: '企业类型不可为空', trigger: 'blur'}],
        management_types: [{required: true, message: '经营类型不可为空', trigger: 'blur'}],
        fan_standard_current: [{required: true, message: '风机标准电流不可为空', trigger: 'blur'},{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        filter_standard_current: [{required: true, message: '净化器标准电流不可为空', trigger: 'blur'},{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        voc_factory_name: [{required: true, message: '监测仪品牌不可为空', trigger: 'blur'}],
        principle: [{required: true, message: '检测原理不可为空', trigger: 'blur'}],
        power_supply_mode: [{required: true, message: '接电方式不可为空', trigger: 'blur'}],
        voc_factory_model_id: [{required: true, message: '监测仪型号不可为空', trigger: 'blur'}],
        send_mode: [{required: true, message: '传输方式不可为空', trigger: 'blur'}],
        voc_mn: [{required: true, message: '监测仪编码', trigger: 'blur'}],
        fan_power:[{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        fan_speed:[{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        purifying_rate:[{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        pipe_area: [{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        stove_length: [{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        stove_width: [{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
      },
      factoryList: [],
      factoryModelList: [],
      maintenanceList: [],
      status: null
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    "formData.pics": {
      handler: function (val) {
        if (val) {
          this.fileList = Array.from(this.formData.pics.split(','))
        } else {
          this.fileList = []
        }
      }
    },
    "formData.voc_factory_name": {
      handler: function (val) {
        let param = {
          StartAt: 0,
          Size: 20,
          Param:
            {
              FactoryName: val,
              ModelType: 1
            }
        }
        this.$post('admin/listFactoryModel', param).then(res => {
          this.factoryModelList = res.content
        })
      }
    }
  },
  methods: {
    init() {
      const user = getUserInfo()
      this.userInfo = user.Info
      this.$post('admin/listFactory', {}).then(res => {
        this.factoryList = res.content
      })
      this.$get("admin/listMaintainer").then((res) => {
        this.maintenanceList = res;
      });
    },
    handleEditDialogClose: function () {
      if (this.changeState) {
        this.changeState = false
      }
      this.handleClose()
    },
    hasChange() {
      this.changeState = true
    },
    handlePaste() {
      this.changeState = true
      const arr = this.formData.lng.split(',')
      this.formData.lng = arr[0]
      this.formData.lat = arr[1]
    },
    handleOwnerChange(v) {
      this.changeState = true
    },
    /**
     * 监测仪品牌选择后调用
     * @param value
     */
    handleFactorySelect(value) {
      this.changeState = true
      let param = {
        StartAt: 0,
        Size: 20,
        Param:
          {
            FactoryName: value,
            ModelType: 1
          }
      }
      this.$post('admin/listFactoryModel', param).then(res => {
        this.factoryModelList = res.content
      })
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },
    handleSubmit() {
      if (!this.changeState && this.title !== '审核') {
        this.$emit("handleClose")
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              let userInfo = getUserInfo().Info
              para.owner = ownerJoin(this.formData.owner, userInfo)
              para.area_id = join(para.area_id)
              para.pics = this.fileList
              let param = new URLSearchParams()
              Object.entries(para).forEach(([key, val]) => {
                if (key == 'cuisine') {
                  if (val.length > 1) {
                    param.append(key, val[1])
                  } else {
                    this.$message({
                      message: '菜系选择有误',
                      type: 'info'
                    });
                    return
                  }
                } else {
                  param.append(key, val)
                }
              })
              if (this.title === '新增') {
                this.$post('admin/locale_exam/add', param).then(res => {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  });
                  this.$emit("handleClose")
                })
              } else if (this.title === '编辑') {
                this.$post('admin/locale_exam/edit/' + this.formData.id, param).then(res => {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                  this.$emit("handleClose")
                })
              } else if (this.title === '审核') {
                param.set('status', this.status)
                param.set('remark', this.formData.remark)
                this.$post('admin/locale_exam/edit/' + this.formData.id, param).then(res => {
                  this.$message({
                    message: '审核成功',
                    type: 'success'
                  });
                  this.$emit("handleClose")
                })
              }

            })

        }
      })
    },
    handleClose() {
      this.$emit("handleClose")
    }
  }
}
</script>
<style scoped lang="scss">
.form-part {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #eeeeee;
}

.form-part-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEEF5
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 100px;
  height: 100px;
}


.wordWrapClass {
  vertical-align: center;
  writing-mode: vertical-lr;
  text-align: center;
  letter-spacing: 6px;
  font-weight: bold;
}
</style>
